<template>
        <div id="bg" class="home_marriage_test" >
            <header id="agentTitle">
                <div class="form_box">
                    <div class="title_img_box_"><img src="http://cdn.zhiyileiju.cn/WechatIMG1253.png" alt=""></div>
                    <p class="form_box_title_"><img src="http://cdn.zhiyileiju.cn/WechatIMG1247.png" alt=""></p>
                    <van-form >
                        <div class="bottom_border_box" style="padding:10px 16px">
                            <span style="width: 6.2em;margin-right:2em">姓</span>名<input class="input_color"  :style="{'--scss_var_':scss_var_color,'--scss_var_opacity':scss_var_opacity,'--scss_var_input_color':scss_var_input_color}" style="margin-left: 2em;width: 3rem;" type="text" v-model="username" @input="inputChange" placeholder="请输入姓名">
                        </div>
                        <div class="bottom_border_box">
                            <van-radio-group v-model="radio" direction="horizontal" style="padding:10px 16px">
                                <span style="letter-spacing:2em;width: 6.2em;">性别</span>
                                <van-radio name="1" checked-color="#EC3C3C" style="font-size: .32rem;color:#525252;">男</van-radio>
                                <van-radio name="2" checked-color="#EC3C3C" style="font-size: .32rem;">女</van-radio>
                            </van-radio-group>
                        </div>
                        <div class="bottom_border_box birth_date" style="padding:10px 16px;border-bottom:none">
                            <span >出生日期</span> <span class="birth_date_span" :style="birth_date_value==='请选择出生时间'? color_red :typeface_style" @click="chooseYourTimeOfBirthButtom">{{birth_date_value}}</span>
                        </div>
                    </van-form>
                    <div class="button_box__ " @click="submitButton('top')"><img class="animation_scaling" src="http://cdn.zhiyileiju.cn/WechatIMG1434.jpeg" alt=""></div>
                </div>
            </header >
             <p v-if="rm" style="font-size:12px;color:#999;text-align:center;margin-top:-35px;height:35px">测试仅供娱乐</p>
            <section> 
                    <p v-if="!rm"  class="text_p" style="margin-bottom: 0.2rem;margin-top:0;">-已为 <span style="color:#FFCE71;">8,998,716</span>  人测试, 准确率很高-</p>
                    <div v-if="!rm" id="parent" class="message_box">
                        <vue-seamless-scroll :data="data_list" :class-option="defaultOption" class="warp">
                            <ul id="child1" >
                                <li v-for=" item in data_list" :key="item.id"> <span>{{item.time_unit}}</span> <span>{{item.city}}</span>  <span>{{item.uname}}</span> <span>{{item.action}}</span></li>
                            </ul>
                        </vue-seamless-scroll>
                    </div>
                    <!-- 隐私协议入口 -->
                    <p class="text_p" v-if="privacy_agreement_population" style="display: flex;justify-content: center;margin-top:0;"> <van-checkbox style="margin-top: .34rem;" v-model="privacy_agreement"  shape="square" icon-size=".3rem"><p class="text_p" style="margin-top:0;">您的个人信息仅用于生成结果，</p></van-checkbox>  <span class="text_p" @click="privacyAgreementExamineButtom">查看 &lt;隐私协议&gt;</span>  </p>
                    <!-- <p v-if="!rm"  class="text_p">-已为 <span style="color:#FBEC7B;">789, 316</span>  人测试, 准确率很高-</p> -->

                    


                    <p v-if="rm"  style="display: flex;justify-content: center;margin-top:0;margin-top:10px" class="text_p" >本表单提交代表您已允许我们收集相关信息，且仅用作相关广告内容服务</p>
                <h3>资深专家团队倾力打造</h3>
                
            </section>
            <footer>
                <copyrightInformation/>
                
                
                <div class="top_brand">
                    <div class="img_box" style="width: 1.56rem"><img src="http://cdn.zhiyileiju.cn/home_marriage_test_brand_3.jpg" alt=""></div>
                    <div class="img_box" style="width: 1.7rem"><img src="http://cdn.zhiyileiju.cn/home_marriage_test_brand_2.jpg" alt=""></div>
                    <div class="img_box" style="width: 2.51rem"><img src="http://cdn.zhiyileiju.cn/home_marriage_test_brand_1.jpg" alt=""></div>
                </div>
                <div class="buttom_anchor animation_scaling" v-if="fixedbtn" @click="backToTheTopCallback">
                立即测算
                </div>
            </footer>
            
            <!-- 选择日期组件 -->
            <DatetimePicker :date="currentDate" v-model="boyShow" @confirm="boyConfirm"></DatetimePicker>
            <van-popup v-model="lookrules" style="width:90%;max-height:500px;" closeable>
			<div style="padding:.5rem;padding-top:20px;font-size:.26rem">
				<h1 class="center" style="text-align: center;margin: 0.5rem 0;font-size: 0.5rem;">用户隐私协议</h1>
            尊敬的用户，欢迎阅读本协议：<br/><br/>
            {{privacy_agreement_company}}依据本协议的规定提供服务，本协议具有合同效力。您必须完全同意以下所有条款，才能保证享受到更好的{{privacy_agreement_company}}服务。您使用服务的行为将视为对本协议的接受，并同意接受本协议各项条款的约束。<br/><br/>

            用户在申请{{privacy_agreement_company}}服务过程中，需要填写一些必要的个人信息，为了更好的为用户服务，请保证提供的这些个人信息的真实、准确、合法、有效并注意及时更新。若因填写的信息不完整或不准确，则可能无法使用本服务或在使用过程中受到限制。如因用户提供的个人资料不实或不准确，给用户自身造成任何性质的损失，均由用户自行承担。<br/><br/>

            保护用户个人信息是{{privacy_agreement_company}}的一项基本原则，{{privacy_agreement_company}}运用各种安全技术和程序建立完善的管理制度来保护用户的个人信息，以免遭受未经授权的访问、使用或披露。未经用户许可{{privacy_agreement_company}}不会向第三方（{{privacy_agreement_company}}控股或关联、运营合作单位除外）公开、透露用户个人信息，但由于政府要求、法律政策需要等原因除外。<br/><br/>

            在用户发送信息的过程中和本网站收到信息后，本网站将遵守行业通用的标准来保护用户的私人信息。但是任何通过因特网发送的信息或电子版本的存储方式都无法确保100%的安全性。因此，本网站会尽力使用商业上可接受的方式来保护用户的个人信息，但不对用户信息的安全作任何担保。<br/><br/>

            此外，您已知悉并同意：在现行法律法规允许的范围内，{{privacy_agreement_company}}科技可能会将您非隐私的个人信息用于市场营销，使用方式包括但不限于：在网页或者app平台中向您展示或提供广告和促销资料，向您通告或推荐服务或产品信息，使用电子邮件，短信等方式推送其他此类根据您使用{{privacy_agreement_company}}科技服务或产品的情况所认为您可能会感兴趣的信息。<br/><br/>

            本网站有权在必要时修改服务条例，本网站的服务条例一旦发生变动，将会在本网站的重要页面上提示修改内容，用户如不同意新的修改内容，须立即停止使用本协议约定的服务，否则视为用户完全同意并接受新的修改内容。根据客观情况及经营方针的变化，本网站有中断或停止服务的权利，用户对此表示理解并完全认同。<br/><br/>

            如果您还有其他问题和建议，可以通过电子邮件673681507@qq.com或者电话17620368211联系我们。

            {{privacy_agreement_company}}保留对本协议的最终解释权。
                <van-button class="redbtn" style="margin-top: .6rem;color: #C41713;background: #FEC995" @click="lookrules=false" round>关闭</van-button>
            </div>
		    </van-popup>
            <!-- 红包1 -->
            <div class="red_packet_box" v-if="red_packet_box_fal">
                <h1>恭喜获得红包！</h1>
                <h2>{{price.discount_amount}}<span>元</span></h2>
                <p>可减价查看流年测试结果</p>
                <div class="count_down_box">
                    <van-count-down :time="counttime" millisecond>
						<template #default="timeData">
							<span class="block">{{ timeData.hours }}</span>
							<span class="colon">:</span>
							<span class="block">{{ timeData.minutes }}</span>
							<span class="colon">:</span>
							<span class="block">{{ timeData.seconds }}</span>
							<span class="colon">:</span>
							<span class="block">{{ parseInt(timeData.milliseconds / 10) }}</span>
                            <span class="colon text">后失效</span>
						</template>	
					</van-count-down>
                </div>
                <!-- <van-button class="buttom" @click="employRedPacket" round>点击立即使用</van-button> -->
                <div class="buttom animation_scaling" @click="employRedPacket('1')" ><img src="https://cdn.zhiyileiju.cn/WechatIMG284.jpeg" alt=""></div>
                <div class="buttom" @click="cruelTefusedToButtom" >残忍拒绝</div>
            </div>
            <!-- 红包2 -->
            <div class="red_packet_box red_packet_box2" v-if="red_packet_box_fal2">
                <h1>恭喜大额红包！</h1>
                <h2>{{price.big_discount_amount}}<span>元</span></h2>
                <p class="p">查看流年结果<span>仅需{{((price.lucky_amount*10*10) - (price.big_discount_amount*10*10))/100}}元</span></p>
                <p class="p_span"><span>错过没有了哟</span></p>
                <!-- <div class="count_down_box">
                    <van-count-down :time="counttime" millisecond>
						<template #default="timeData">
							<span class="block">{{ timeData.hours }}</span>
							<span class="colon">:</span>
							<span class="block">{{ timeData.minutes }}</span>
							<span class="colon">:</span>
							<span class="block">{{ timeData.seconds }}</span>
							<span class="colon">:</span>
							<span class="block">{{ parseInt(timeData.milliseconds / 10) }}</span>
                            <span class="colon text">后失效</span>
						</template>	
					</van-count-down>
                </div> -->
                <div class="buttom animation_scaling" @click="employRedPacket('2')" ><img src="https://cdn.zhiyileiju.cn/WechatIMG294.jpeg" alt=""></div>
                <div class="buttom" @click="browseWithMore" >浏览更多</div>
            </div>
            <!-- 红包1遮罩层 -->
            <div class="mask_layer" @click="redPacketClose" v-if="red_packet_box_shade_fal"></div>
            <!-- 红包2遮罩层 -->
            <!-- <div class="mask_layer" @click="redPacketClose" v-if="red_packet_box_fal2"></div> -->
            <!-- ------------ -->
            <van-overlay :show="shopPayConfirm" z-index="10" lock-scroll>
                <div class="wrapper center" @click.stop>
                    <!-- <div class="block">
                        <div class="title">你支付完成了吗？</div>
                        <div class="payed animation_scaling">
                            <van-button round block color="#000" @click="dcheckorder" icon="https://cdn.zhiyileiju.cn/WechatIMG226.png" class="Dcheckorder" type="primary">点击查看姻缘结果</van-button>
                        </div>
                        <div class="unpay payed" @click="unpay">
                           <van-button round block color="#d8d8d8"  @click="unpay" class="Dcheckorder"  plain>未支付，点击再次支付</van-button>
                        </div>
                    </div> -->
                     <div class="block">
                            <div class="title">距运势解析只差1步<br></div>
                            <div class="title title_tow">支付后查看虎年运势详批</div>
                            <div class="bg_box_chunk_">
                                <span class="only_remain_text">仅剩{{percentage_num}}%</span>
                                <div class="img_bg_box"></div>
                                <div class="bottom_text_box__">
                                    <span class="bottom_text__">信息提交</span>
                                    <span class="bottom_text__">生成报告</span>
                                    <span class="bottom_text__">查看运势详批</span>
                                </div>
                            </div>
                            <div class="payed payment_" @click="employRedPacket('')"><img src="http://cdn.zhiyileiju.cn/WechatIMG600.jpeg" alt=""></div>
                            <div class="payed unpay_" @click="unpay">{{popup_text}}</div>
                            <div class="payed  Dcheckorder_" @click="dcheckorder">已完成支付</div>
                            <div class="count_down_box_">
                                <van-count-down :time="counttime" millisecond>
                                    <template #default="timeData">
                                        <span class="block_">{{ timeData.hours }}</span>
                                        <span class="colon">:</span>
                                        <span class="block_">{{ timeData.minutes }}</span>
                                        <span class="colon">:</span>
                                        <span class="block_">{{ timeData.seconds }}</span>
                                        <span class="colon">:</span>
                                        <span class="block_">{{ parseInt(timeData.milliseconds / 10) }}</span>
                                        <span class="colon text">后失效</span>
                                    </template>	
                                </van-count-down>
                            </div>
                        </div>
                </div>
            </van-overlay>
             <!--  投诉 -->
            <span  class="fixation_complaint" v-if="complaint_buttom_fal" @click="complaintButtom">
                <img src="https://cdn.zhiyileiju.cn/WechatIMG559.pic" alt="">
            </span>
        </div>
</template>

<script>
import DatetimePicker from "../../components/DatetimePicker"
import vueSeamlessScroll from "vue-seamless-scroll";
import Comments from "../../components/Comment.vue";
import copyrightInformation from "../../components/copyrightInformation.vue";
import fleetingTimeFortune from "../../mixin/fleetingTimeFortune"
export default {
    mixins:[fleetingTimeFortune],
    components:{
        DatetimePicker,vueSeamlessScroll,copyrightInformation,Comments
    }
}
</script>

<style lang="scss" scoped>
    #bg{
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        overflow: auto;
        header{
            height: 8.74rem;
            background:url("http://cdn.zhiyileiju.cn/WechatIMG1240.jpeg") no-repeat  center;
            background-size: 100% 100%;
            overflow: hidden;
            .form_box{
                position: absolute;
                left: 50%;
                transform: translate(-50%);
                width: 6.66rem;
                margin: 0 auto;
                padding:.24rem;
                border-radius:.1rem;
                color: #525252;
                background:#FFFAE8;
                // background:#fff url("http://cdn.zhiyileiju.cn/WechatIMG324.jpeg") no-repeat  center;
                // background-size: 95% 90%;
                margin-top: 6.83rem;
                z-index: 1;
                .title_img_box_{
                    position: absolute;
                    width: 4.24rem;
                    top: -0.5rem;
                    left: 50%;
                    transform: translate(-50%);
                }
                .form_box_title_{
                    width: 3.47rem;
                    margin: auto;
                    margin-top: .47rem;
                    margin-bottom: .36rem;
                }
                
                .birth_date{
                     .birth_date_span{
                         display: inline-flex;
                         opacity: .5;
                         padding-left: 0.6rem;
                         white-space: nowrap;
                         overflow: hidden;
                         width: 4rem;
                     }
                    @media screen and (max-width: 321px) {
                        .birth_date_span{
                            width: 3.94rem;
                            font-size: 0.24rem; 
                        }
                    }

                 }
                .bottom_border_box{
                    border-bottom: 1px solid rgba(251, 134, 134,0.2);
                    
                    input{
                         background: none;
                         outline: none;
                         border: none;
                         color: #000;
                    }
                    .input_color{
                        color: var(--scss_var_input_color);
                    }
                     ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                        //  color: #525252;
                        //  opacity: .5;
                        color: var(--scss_var_);
                        opacity: var(--scss_var_opacity) ;
                        font-size: .32rem;
                     }
                     ::-moz-placeholder { /* Firefox 19+ */
                         color: #525252;
                         opacity: .5;
                         font-size: .32rem;
                     }  
 
                     :-moz-placeholder { /* Firefox 19+ */
                         color: #525252;
                         opacity: .5;
                         font-size: .32rem;
                     }  
 
                     :-ms-input-placeholder { /* IE 10+ */
                         color: #525252;
                         opacity: .5;
                         font-size: .32rem;
                     }  
                }
               
                .button_box__{
                    width: 5.78rem;
                    position: absolute;
                    // top: 5.2rem;
                    left: 50%;
                    transform: translate(-50%);
                    z-index: 1;
                    margin-top: 0.6rem;
                }
            }
            .text_p{
                font-family:'MicrosoftYaHei';
                font-size: .24rem;
                text-align: center;
                color: #FFFFFF;
                margin-top: .34rem;
                // .van-checkbox__icon--checked.van-icon{
                //     color:#fff;
                //     background-color:red !important;
                //     border-color:red !important;
                // }
            }

        }
        section{
            position: relative;
            padding: 0 .42rem;
            background: #b70a0e;
            margin-top: -.4rem;
            padding-top: 4.5rem;
            .text_p{
                font-family:'MicrosoftYaHei';
                font-size: .24rem;
                text-align: center;
                color: #FCAF93;
                margin-top: .34rem;
                // margin-bottom: .6rem;
                // .van-checkbox__icon--checked.van-icon{
                //     color:#fff;
                //     background-color:red !important;
                //     border-color:red !important;
                // }
            }
            
             .message_box{
                 position: relative;
                 margin: 0 auto;
                 padding: .28rem .23rem;
                 border: 1px solid #FCAF93;
                 width: 5.7rem;
                 max-height: 2.86rem;
                 overflow-y: hidden; 
                 ul,.child{
                     li{
                         margin-bottom: .29rem;
                         span{
                             display: inline-block;
                             font-size: .2224rem;
                             // margin-right: .5rem;
                             color: #FCAF93
                             
                         }
                         span:nth-child(1){
                             width: 1.3rem;
                         }
                         span:nth-child(2){
                             width: .8rem;
                         }
                         span:nth-child(3){
                             width: 1rem;
                         }
                         span:last-child{
                             margin-right:0 ;
                         }
                     }
                     li:last-child{
                         margin-bottom: 0;
                     }
                 }
             }
             h3{
                 font-family: 'MicrosoftYaHei';
                 text-align: center;
                 color: #FFCE71;
                 font-size: .4rem;
                 margin-top:.3rem;
             }
            .img_box__{
                position: relative;
                width: 7.24rem;
                left: -.3rem;
                margin-bottom: .47rem;
               
            }
            .good_reputation_box_{//好评如潮
                position: relative;
                width: 7.24rem;
                left: -.3rem;
                margin-bottom: .47rem;
                background: url('http://cdn.zhiyileiju.cn/WechatIMG703.jpeg') no-repeat center;
                background-size: 100% 100%;
                padding-top: 1.5rem;
                .img_box_text__{
                    width: 4.48rem;
                    text-align: center;
                    margin: auto;
                    margin-top: -1.42rem;
                    margin-bottom: 0.2rem;
                }
            } 

            .form_box{
                width: 6.66rem;
                margin: 0 auto;
                padding:.24rem;
                border-radius:.1rem;
                color: #525252;
                // background:#FFF0D3 url("http://cdn.zhiyileiju.cn/home_marriage_test_from_bg.jpg") no-repeat;
                background:#fff url("http://cdn.zhiyileiju.cn/WechatIMG324.jpeg") no-repeat  center;
                background-size: 95% 90%;
                margin-left: -.21rem;
                .birth_date{
                     .birth_date_span{
                         display: inline-flex;
                         opacity: .5;
                         padding-left: 0.6rem;
                         white-space: nowrap;
                         overflow: hidden;
                         width: 4rem;
                     }
                    @media screen and (max-width: 321px) {
                        .birth_date_span{
                            width: 3.94rem;
                            font-size: 0.24rem; 
                        }
                    }

                 }
                .bottom_border_box{
                    border-bottom: 1px solid rgba(138, 0, 0,0.2);
                    
                    input{
                         background: none;
                         outline: none;
                         border: none;
                         color: #000;
                    }
                    .input_color{
                        color: var(--scss_var_input_color);
                    }
                     ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                        //  color: #525252;
                        //  opacity: .5;
                        color: var(--scss_var_);
                        opacity: var(--scss_var_opacity) ;
                        font-size: .32rem;
                     }
                     ::-moz-placeholder { /* Firefox 19+ */
                         color: #525252;
                         opacity: .5;
                         font-size: .32rem;
                     }  
 
                     :-moz-placeholder { /* Firefox 19+ */
                         color: #525252;
                         opacity: .5;
                         font-size: .32rem;
                     }  
 
                     :-ms-input-placeholder { /* IE 10+ */
                         color: #525252;
                         opacity: .5;
                         font-size: .32rem;
                     }  
                }
               
            }
             .button_box__{
                width: 5.70rem;
                height: .92rem;
                line-height: .92rem;
                border-radius: .92rem;
                font-size: .35rem;
                color: #fff;
                background: linear-gradient(0deg, #EF313A 0%, #FDA46F 100%);
                box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.36);
                margin: auto;
                margin-top: .35rem;
                text-align: center;
                text-align: -webkit-center;
                animation-name: myfirst;
                animation-duration: 1s;
                animation-iteration-count:infinite;
                -webkit-animation-iteration-count:infinite; /* Safari 和 Chrome */
            }

          
            
        }
        footer{
            padding-bottom: 1.5rem;
            background: #b70a0e;
            // padding-top: .35rem;
            .buttom_anchor{
                position: fixed;
                bottom: 0;
                width: 7.5rem;
                text-align: center;
                line-height: 1.11rem;
                font-size: .36rem;
                background: #FFCE71;
                color: #B70A0E;
                height: 1.11rem;
                box-shadow: 0px -2px 21px 3px rgba(154, 2, 23, 0.81);
            }
            .text_p{
                font-family:'MicrosoftYaHei';
                font-size: .24rem;
                text-align: center;
                color: #FBEC7B;
                padding:0 .5rem;
            }
            .top_brand{
                text-align: center;
                .img_box{
                    display: inline-block;
                    margin-right: .2rem;
                }
                .img_box:last-child{
                     margin-right: 0;
                }
            }
        }
        @keyframes myfirst
         {
             0%   {transform:scale(1);}
             50%  {transform:scale(1.1)}
             100% {transform:scale(1)}
         }
        //  红包1
        .red_packet_box{
            width: 5.25rem;
            height: 8.23rem;
            position: fixed;
            top: 0;
            bottom: 0;
            left: 0;
            margin:  auto;
            right: 0;
            // width: 1rem;
            background: url('https://cdn.zhiyileiju.cn/WechatIMG283.png') no-repeat;
            background-size: 100% 100%;
            z-index:100;
            text-align: center;
            // 20 24  351
            h1{
                text-align: center;
                margin-top: 1rem;
                color: #E38000;
                font-size: .35rem;
            }
            h2{
                font-weight: 600;
                margin-top: .2rem;
                font-size: 1rem;
                color: red;
                span{
                    font-size: .35rem;
                }
            }
            p{
                margin-top: .2rem;
                font-size: .25rem;
                color: rgb(195, 125, 63);
            }
            .count_down_box{
                margin-top: 1.5rem;
                .colon {
                    display: inline-block;
                    margin: 0 .04rem;
                    font-size: .2rem;
                    color: #fff;
                }
                .block {
                    display: inline-block;
                    width: .43rem;
                    color: #fff;
                    font-size: .24rem;
                    text-align: center;
                    background-color: #b63437;
                    box-sizing:border-box;
                    border: 1px solid #fa455f;
                }
                .text{
                    margin-left: .1rem;
                    font-size: .2rem;
                }
            }
            .buttom{
                width: 3.51rem;
                margin: auto;
                margin-top: .44rem;
            }
            .buttom:last-child{
                 color:#f7b352 ;
                font-size: .42rem;
                border: 1px solid #d49c46;
                border-radius: .81rem;
                background: rgba(255,255,255,0);
                height: .81rem;
                line-height: .81rem;
                margin-top:-.1rem;
            }
            
        }
        // 红包2
        .red_packet_box2{
            width: 7.5rem;
            // height: 9.60rem;
            height: 10rem;
            background: url('https://cdn.zhiyileiju.cn/WechatIMG293.png') no-repeat;
            background-size: 100% 100%;
            z-index:100;
            text-align: center;
            h1{
                margin-top: 1.5rem;
            }
            h2{
                font-size: 1rem;
            }
            .p{
                margin-top: 1.8rem;
                font-size: .28rem;
                color: #FECA77;
                span{
                    font-size: .32rem;
                }
            }
            .p_span{
                color: #FECA77;
                font-size: .22rem;
                margin-top: .05rem;
            }
            .buttom{
                // margin-top: .2rem;
                margin-top: .2rem;
            }
            .buttom:last-child{
                margin-top: -.1rem;
            }
        }
        //遮罩层
        .mask_layer{
            position: fixed;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            background: rgba(0,0,0,.5);
            z-index: 10;
        }

        // .wrapper{
        //     width: 7.5rem;
        //     height: 100%;
        //     margin: 0 auto;
        //     .block {
        //         position: fixed;
        //         top:50%;
        //         left: 50%;
        //         margin: 0 auto;
        //         width: 6rem;
        //         transform: translate(-50%,-50%);
        //         background-color: white;
        //         border-radius: .6rem;
        //         padding: 0.5rem 0;
        //         .title{
        //             line-height: 44px;
        //             text-align: center;
        //             font-size: .4rem;
        //             // border-bottom: 1px solid #eeeeee;
        //         }
        //         .payed{
        //             color: #b91e1e;
        //             font-size: 18px;
        //             line-height: 80px;
        //             text-align: center;
        //             margin-bottom: .6rem;
        //             // border-bottom: 1px solid #eeeeee;
        //             .Dcheckorder{
        //                 width: 85%;
        //                 margin: 10px auto;
        //                 animation: scaleDrew 1.5s ease-in-out infinite;
        //                 font-size: 18px;
        //                 .van-button__icon{
        //                     font-size: 1.9em !important;
        //                 }
        //             }
        //         }
        //         .unpay{
        //             color: #999;
        //             font-size: 18px;
        //             text-align: center;
        //             line-height: 60px;
        //             button{
        //                 color: #000 !important;
        //             }
        //         }
        //     }
        // }


         .wrapper{
        width: 7.5rem;
        height: 100%;
        margin: 0 auto;
            .block {
                position: fixed;
                top:50%;
                left: 50%;
                margin: 0 auto;
                width: 5.5rem;
                height: 9.3rem;
                transform: translate(-50%,-50%);
                background-color: white;
                border-radius: .4rem;
                padding: 0.4rem 0;
                .title{
                    color: #CF031A;
                    text-align: center;
                    font-size: .352rem;
                    font-weight: bold;
                    margin: 0;
                    margin-top: .1rem;
                }
                .title_tow{
                    margin-top: .2rem;
                }
                .bg_box_chunk_{
                    overflow: hidden;
                    margin-top: .9rem;
                    .img_bg_box{
                        width: 4.48rem ;
                        height: .5rem ;
                        background:url('https://cdn.zhiyileiju.cn/WechatIMG599.pic') no-repeat ;
                        background-size: 100% 100%;
                        margin: 0 auto;
                        margin-top: 0.13rem;
                    }
                    .only_remain_text{
                        float: right;
                        font-size: .26rem;
                        color: #F5A625;
                        margin-right: .21rem;
                    }
                    .bottom_text_box__{
                        // position: relative;
                        overflow: hidden;
                        width: 5.5rem;
                        white-space : nowrap;
                        .bottom_text__{
                            // position: absolute;
                            float: left;
                            color: #525252;
                            font-size: .26rem;
                            margin-top: .29rem;
                        }
                        .bottom_text__:nth-child(1){
                            margin-left: .26rem;
                        }
                        .bottom_text__:nth-child(2){
                            margin-left: 1.02rem;
                        }
                    
                        .bottom_text__:nth-child(3){
                            margin-left: .4rem;
                        }
                        @media screen and (max-width: 340px) {
                            .bottom_text__:nth-child(3){
                                margin-left: .2rem;
                            }
                        }
                    }
                }
                .payed{
                    width: 4.5rem;
                    height: .98rem;
                    font-size: .38rem;
                    border-radius: 49px;
                    text-align: center;
                    margin: 0 auto;
                    line-height: .98rem;
                }
                .payment_{
                    margin-top: 1.09rem;
                    color: #fff;
                    background: #CF031A;
                }
                .unpay_{
                    margin-top: .31rem;
                    color: #fff;
                    background: #F5A625;
                }
                .Dcheckorder_{
                    margin-top: .31rem;
                    box-sizing: border-box;
                    border: 1px solid #F5A625;
                }
                .count_down_box_{
                    text-align: center;
                    margin-top: .4rem;
                    .colon {
                        display: inline-block;
                        margin: 0 .04rem;
                        font-size: .2rem;
                        color: #b63437;
                    }
                    .block_ {
                        display: inline-block;
                        width: .43rem;
                        color: #fff;
                        font-size: .24rem;
                        text-align: center;
                        background-color: #b63437;
                        box-sizing:border-box;
                        border: 1px solid #fa455f;
                    }
                    .text{
                        margin-left: .1rem;
                        font-size: .14rem;
                        color: #525252;
                    }   
                }
            }
        }
        //投诉
        .fixation_complaint{
            position: fixed;
            width: .48rem;
            top: 3.02rem;
            right: 0;
            z-index: 10;
        }
    }
</style>

<style lang="scss">
   .home_marriage_test{
       .van-form{
           font-size: .32rem;
           color: #525252;
            .van-icon{
                border: 1px solid #EC3C3C;
            }
           .van-cell{
               background-color: transparent;
           }
           .van-field__label{
               width: 4;
               color: #EC3C3C;
                span:nth-child(1){
                    font-size: .32rem;
                }
            }
       }
       .van-checkbox{
           justify-content: center;
       }
       .van-button--round{
           width: 5.69rem;
           height: .92rem;
       }
        .text_p .van-checkbox__icon--checked .van-icon {
            //隐私协议复选框样式
            color: #FCAF93;
            font-weight: 900;
            background-color: #b70a0e;
            border-color: #FCAF93;
        }
         .van-radio__label{
                    color: #525252 !important;
                }
   }
    
   
</style>